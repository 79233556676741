
        <h4 nz-typography>Quick Message</h4>

        <div class="form-input">
            <form nz-form [formGroup]="quickCampaignForm">
                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Campaign Name</nz-form-label>
                    <nz-form-control [nzSm]="10"  [nzValidatingTip]="note" [nzErrorTip]="nameErrorTpl">
                        <input nz-input placeholder="" formControlName="campaignName" />
                        <ng-template #nameErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">required
                            </ng-container>
                            <ng-container *ngIf="control.hasError('pattern')">{{note}}</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Mobile Number(s)</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-textarea-count>
                            <textarea rows="4" #textArea formControlName="mobileNumber" nz-input placeholder="Mobile Number"
                                (ngModelChange)="mobileNumberInputChange($event)"></textarea>
                        </nz-textarea-count>
                        <a>
                    <span *ngIf="totalMobileNumbers">{{totalMobileNumbers}} Mobile Numbers</span></a>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Message Type</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-radio-group nzButtonStyle="solid" formControlName="serviceType" [(ngModel)]="messageType"
                            (ngModelChange)="getSenderIdsByMsgType($event)">
                            <label nz-radio-button nzValue="trans">Transactional</label>
                            <label nz-radio-button nzValue="promo">Promotional</label>
                            <label nz-radio-button nzValue="others">Service</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="messageType === 'others'">
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Service Type</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-radio-group nzButtonStyle="solid" formControlName="serviceSubType"
                            (ngModelChange)="getSenderIdsByMsgType(messageType,$event)">
                            <label nz-radio-button nzValue="service-implicit">Service Implicit</label>
                            <label nz-radio-button nzValue="service-explicit">Service Explicit</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Sender ID</nz-form-label>
                    <nz-form-control [nzSm]="10">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Sender Id" formControlName="senderId"
                            (ngModelChange)="getTemplates(messageType,$event)" [(ngModel)]="senderIdSelected">
                            <nz-option [nzLabel]="item.senderId" [nzValue]="item.senderId"
                                *ngFor="let item of listOfSenderIds">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Content Template Name</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Template"
                            formControlName="templateName"
                            (ngModelChange)="templateSelected = $event; messageText = templateSelected?.templateText">
                            <nz-option [nzLabel]="item.templateTitle" [nzValue]="item"
                                *ngFor="let item of listOfTemplates">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5"  nzRequired>Message Encoding</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-radio-group nzButtonStyle="solid" formControlName="msgType">
                            <label nz-radio-button nzValue="plain">Plain Text</label>
                            <label nz-radio-button nzValue="unicode">Unicode</label>
                            <label nz-radio-button nzValue="FL">Flash</label>
                            <label nz-radio-button nzValue="FU">Flash Unicode</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5"  nzRequired>Message Part</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-radio-group nzButtonStyle="solid" formControlName="msgPart"
                            [ngModel]="messageText | creditCount:'checkPart'">
                            <label nz-radio-button nzValue="single">Singlepart</label>
                            <label nz-radio-button nzValue="multi">Multipart</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>



                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Message Text</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-textarea-count>
                            <textarea rows="4" #textArea formControlName="msgText" [ngModel]="templateSelected?.templateText" nz-input (input)="msgLength()" placeholder="Message Text"
                                [(ngModel)]="messageText"></textarea>
                        </nz-textarea-count>
                       <a>  {{msgTextLength ==0? messageText.length:msgTextLength}}  characters | {{messageText | creditCount }} SMS Credit </a>
                    </nz-form-control>
                </nz-form-item>



                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Is ShortUrl Selected</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-radio-group formControlName="isShortUrlSelected" [(ngModel)]="isShortUrlSelected">
                            <label nz-radio nzValue="Y">Yes</label>
                            <label nz-radio nzValue="N">No</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="isShortUrlSelected === 'Y'">
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Short URL Name</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-select nzMode="tags" nzPlaceHolder=" Select Short URL Name" formControlName="shortUrlName"
                            (ngModelChange)="onShortUrlSelect($event,textArea)">
                            <nz-option *ngFor="let option of listOfShortUrl" [nzLabel]="option.name"
                                [nzValue]="option.name"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Entity ID</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <input  nz-input placeholder="" formControlName="entityId" placeholder="Entity ID"
                            [ngModel]="templateSelected?.entityId" autocomplete="entityId" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Operator Template Id</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <input nz-input placeholder="" formControlName="dltTemplateId" placeholder="Operator Template Id"
                            [ngModel]="templateSelected?.operatorTemplateId" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="flex items-center mt-1 space-x-2" style="margin-left: 45%;">
                    <nz-form-control>
                        <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
                        <button nz-button (click)="resetForm()">Clear</button>
                    </nz-form-control>
                </nz-form-item>

            </form>
        </div>



<nz-modal [(nzVisible)]="isVisible" nzTitle="Preview" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzOkLoading]="isOkLoading" [nzOkText]="'Send Now'">
    <ng-container *nzModalContent>
        <nz-descriptions nzBordered>
            <nz-descriptions-item nzTitle="Sender ID" [nzSpan]="3">{{senderIdSelected}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Character Count" [nzSpan]="3">{{messageText?.length }} characters</nz-descriptions-item>
            <nz-descriptions-item nzTitle="SMS Credit" [nzSpan]="3">{{messageText | creditCount}} SMS Credit(s)
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Message" [nzSpan]="3">{{messageText}}</nz-descriptions-item>
        </nz-descriptions>
    </ng-container>
</nz-modal>
