<nz-table #sortTable [nzData]="listOfData" [nzData]="total" nzTableLayout="auto" [nzShowPagination]="pagination" nzBordered="true"
    nzShowSizeChanger nzSize="small" [ngClass]="{'smallScreen': isSmallScreen}">
    <!-- [nzScroll]="{ x: '1100px',y: '300px' }" -->
    <!-- [nzScroll]="{ y: '300px' }" -->
    <thead>
        <tr>
            <!-- <th nzWidth="60px"></th> -->

            <th nzWidth="60px" *ngIf="type === 'content-template'" ></th>
            <!-- <th nzWidth="60px" *ngIf="type === 'content-template-search'"></th> -->
            <th nzWidth="60px" *ngIf="type === 'detailed-report'"></th>
            <th nzWidth="60px" *ngIf="type === 'campaignid-report'"></th>

            <th [(nzChecked)]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"
                *ngIf="type === 'contacts'"></th>
            <th [(nzChecked)]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"
                *ngIf="type === 'blacklist'"></th>
            <th *ngFor="let column of listOfColumn"[nzSortFn]="column.compare" [nzSortPriority]="column.priority" [nzLeft]="column?.isFixed"
                [nzSortPriority]="column.priority">
                {{ column.title }}
            </th>

        </tr>

    </thead>
    <tbody *ngIf="type === 'general'">
        <tr *ngFor="let data of sortTable.data">
             <!-- Summary / Sernder ID Report -->
            <td nzLeft *ngIf="data?.summaryDate">{{ data.summaryDate }}</td>
            <td nzLeft *ngIf="data?.receiveDate">{{ data?.receiveDate }}</td>
            <td *ngIf="data?.senderId">{{ data?.senderId }}</td>
            <td *ngIf="data?.totalRequest">{{ data.totalRequest }}</td>
            <td *ngIf="data?.totalRejected">{{ data.totalRejected }}</td>
            <td *ngIf="data?.totalSubmit">{{ data.totalSubmit }}</td>
            <td *ngIf="data?.totalDelivered">{{ data.totalDelivered }}</td>
            <td *ngIf="data?.totalFailed">{{ data.totalFailed }}</td>
            <td *ngIf="data?.totalAwaited">{{ data.totalAwaited }}</td>
        </tr>
        <tr *ngFor="let data of total">
          <td><b>Total</b></td>
          <td *ngIf="data?.senderId"><b>{{ data.senderId }}</b></td>
          <td *ngIf="data?.totalRequest"><b>{{ data.totalRequest }}</b></td>
            <td *ngIf="data?.totalRejected"><b>{{ data.totalRejected }}</b></td>
            <td *ngIf="data?.totalSubmit"><b>{{ data.totalSubmit }}</b></td>
            <td *ngIf="data?.totalDelivered"><b>{{ data.totalDelivered }}</b></td>
            <td *ngIf="data?.totalFailed"><b>{{ data.totalFailed }}</b></td>
            <td *ngIf="data?.totalAwaited"><b>{{ data.totalAwaited }}</b></td>
        </tr>

            <tr *ngFor="let data of sortTable.data">
            <!-- Download Report -->
            <td *ngIf="data?.fromDate">{{ data?.fromDate }}</td>
            <td *ngIf="data?.toDate">{{ data?.toDate }}</td>
            <td *ngIf="data?.fromDate && data?.toDate">{{ data?.campaignName }}</td>
            <td *ngIf="data?.fromDate && data?.toDate">
                <nz-tag [nzColor]="'blue'" *ngIf="data?.status === 'Pending'">{{ data?.status }}</nz-tag>
                <nz-tag [nzColor]="'green'" *ngIf="data?.status === 'Ready'">{{ data?.status }}</nz-tag>
            </td>
            <td *ngIf="data?.downloadReportLink">
              <button   [routerLink]="" (click)="downloadFilee(data)"> Download Link </button>
            </td>
            </tr>


            <!-- Credit History -->
            <tr *ngFor="let data of sortTable.data">
            <td *ngIf="data?.createdDate">{{ data?.createdDate }}</td>
            <td *ngIf="data?.credit">{{ data?.credit }}</td>
            <td *ngIf="data?.updatedCredit && data?.status">{{ data?.status }}</td>
            <td *ngIf="data?.updatedCredit">{{ data?.updatedCredit }}</td>
            <td *ngIf="data?.updatedBy">{{ data?.updatedBy }}</td>
            <td *ngIf="data?.comment">{{ data?.comment }}</td>
        </tr>

    </tbody>

    <tbody *ngIf="type === 'sender-id'">
        <tr *ngFor="let data of sortTable.data">
            <td>{{ data?.senderId }}</td>
            <td>{{ data?.isActive }}</td>
            <td>{{ data?.isDefault }}</td>
            <td>{{ data?.entityId }}</td>
            <td>{{ data?.headerId }}</td>
            <td>{{ data?.senderIdType }}</td>
            <!-- <td>{{ data?.createdDate }}</td> -->
            <!-- <td>
            <a (click)="showDeleteConfirm(data)">Delete</a>
            </td> -->
        </tr>
    </tbody>

    <tbody *ngIf="type === 'content-template'">

        <ng-container *ngFor="let data of sortTable.data">
            <tr>
                <td [nzExpand]="expandSet.has(data.templateId)"
                    (nzExpandChange)="onExpandChange(data.templateId, $event)"></td>
                <td>{{ data?.templateTitle }}</td>
                <td>{{ data?.templateDescription }}</td>
                <td>{{ data?.senderId }}</td>
                <td>{{ data?.status }}</td>
                <td>{{ data?.contentTemplateType }}</td>
                <!-- <td>
                    <a (click)="showDeleteConfirm(data)">Delete</a>
                </td> -->
                <!-- <td>
                  <button (click)="showEditModal(data)" value={{data.senderId}}+{{data.templateText}}+{{data.templateDescription}}+{{data.entityId}}+{{data.operatorTemplateId}}+{{data.templateId}}+{{data.status}}+{{data.contentTemplateType}}+{{data.templateTitle}}+{{data.templateType}}+{{data.variableCount}}  data-target="#exampleModal">Edit</button>
              </td> -->
            </tr>
            <tr [nzExpand]="expandSet.has(data.templateId)">
                <nz-descriptions nzBordered="false" [nzSize]="'small'">
                    <nz-descriptions-item nzTitle="Content Template SubType">{{data.contentTemplateSubType}}</nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Entity Id">{{data.entityId}}</nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Operator Template Id">{{data.operatorTemplateId}}
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Message Text" [nzSpan]="4">
                        {{data.templateText}}
                    </nz-descriptions-item>
                </nz-descriptions>
            </tr>
        </ng-container>

    </tbody>


    <tbody *ngIf="type === 'content-template-search'">

        <ng-container *ngFor="let data of sortTable.data">
            <tr>
                <td [nzExpand]="expandSet.has(data.templateId)"
                    (nzExpandChange)="onExpandChange(data.templateId, $event)"></td>
                <td>{{ data?.templateTitle }}</td>
                <td>{{ data?.templateDescription }}</td>
                <td>{{ data?.senderId }}</td>
                <td>{{ data?.status }}</td>
                <td>{{ data?.contentTemplateType }}</td>
                <!-- <td>
                    <a (click)="showDeleteConfirm(data)">Delete</a>
                </td> -->
            </tr>
            <tr [nzExpand]="expandSet.has(data.templateId)">
                <nz-descriptions nzBordered="false" [nzSize]="'small'">
                    <nz-descriptions-item nzTitle="Content Template SubType">{{data.contentTemplateSubType ? 'null' :
                        data.contentTemplateSubType}}</nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Entity Id">{{data.entityId}}</nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Operator Template Id">{{data.operatorTemplateId}}
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Message Text" [nzSpan]="4">
                        {{data.templateText}}
                    </nz-descriptions-item>
                </nz-descriptions>
            </tr>
        </ng-container>

    </tbody>

    <tbody *ngIf="type === 'detailed-report'">
        <ng-container *ngFor="let data of sortTable.data">

            <tr>
                <td [nzExpand]="expandSet.has(data.messageId)"
                    (nzExpandChange)="onExpandChange(data.messageId, $event)"></td>
                <td>{{ data?.receiveDate }}</td>
                <td>{{ data?.senderId }}</td>
                <td>{{ data?.messageId }}</td>
                <td>{{ data?.campaignName }}</td>
                <td>{{ data?.mobileNumber }}</td>
                <td>{{ data?.sentDate }}</td>
                <td>{{ data?.deliveryStatus }}</td>
                <td>{{ data?.deliveryErrorCode }}</td>
                <td>{{ data?.deliveryDateTime }}</td>
            </tr>

            <tr [nzExpand]="expandSet.has(data.messageId)">
                <nz-descriptions nzBordered="false" [nzSize]="'small'">
                    <nz-descriptions-item nzTitle="Error Description">{{data.errorDescription}}
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Message Text" [nzSpan]="3">
                        {{data.messageText}}
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Template Id" [nzSpan]="3">
                        {{data.templateId}}
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Message Count" [nzSpan]="3">
                        {{data.messageCount}}
                    </nz-descriptions-item>
                </nz-descriptions>
            </tr>
        </ng-container>
    </tbody>

    <tbody *ngIf="type === 'groups'">
        <tr *ngFor="let data of sortTable.data">
            <td *ngIf="data.groupName">{{ data.groupName }}</td>
            <td *ngIf="data.groupDescription">{{ data.groupDescription }}</td>
            <td>{{ data.numbersInGroupCount }}</td>
            <!-- <td *ngIf="data.groupId">
                <a nz-popconfirm nzPopconfirmTitle="Are you sure ?" nzOkText="ok" nzCancelText="cancel"
                    (nzOnConfirm)="delete(data)">Delete</a>
            </td> -->
        </tr>
    </tbody>

    <tbody *ngIf="type === 'contacts'">
        <tr *ngFor="let data of sortTable.data">
            <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
            <td>{{ data.groupName }}</td>
            <td>{{ data.contactNumber }}</td>
            <td>{{ data.contactName }}</td>
        </tr>
    </tbody>

    <tbody *ngIf="type === 'blacklist'">
        <tr *ngFor="let data of sortTable.data">
            <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
            <td>{{ data.userBlackListNumber }}</td>
        </tr>
    </tbody>

    <tbody *ngIf="type === 'campaignid-report'">
        <ng-container *ngFor="let data of sortTable.data">
            <tr>
                <td [nzExpand]="expandSet.has(data.campaignId)"
                    (nzExpandChange)="onExpandChange(data.campaignId, $event)"></td>
                <td>{{ data.summaryDate }}</td>
                <td>{{ data?.campaignName }}</td>
                <td>{{ data.totalRequest ? data.totalRequest : '0' }}</td>
                <td>{{ data.totalRejected ? data.totalRejected : '0' }}</td>
                <td>{{ data.totalSubmit ? data.totalSubmit : '0' }}</td>
                <td>{{ data.totalDelivered ? data.totalDelivered: '0' }}</td>
                <td>{{ data.totalFailed ? data.totalFailed : '0' }}</td>
                <td>{{ data.totalAwaited ? data.totalAwaited : '0' }}</td>
                <!-- <td>
                    <button nz-button nzType="primary" [nzSize]="'small'" (click)="viewAnalyticsReport(data)">Analytics Report</button>
                </td> -->
                <td>
                    <button nz-button nzType="primary" [nzSize]="'small'" (click)="onRowClick(data)">Download</button>
                </td>
            </tr>

            <tr [nzExpand]="expandSet.has(data.campaignId)">
                <nz-descriptions nzBordered="false" [nzSize]="'small'">
                    <nz-descriptions-item nzTitle="Sender Id">{{data.senderId }}</nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Schedule Date/Time">{{data.scheduleDate}} {{data.scheduleTime}}
                    </nz-descriptions-item>
                    <!-- <nz-descriptions-item nzTitle="Unique Clicks">{{data.uniqueClicks}} -->
                    <!-- </nz-descriptions-item> -->
                    <nz-descriptions-item nzTitle="Message Text" [nzSpan]="4">
                        {{data.messageText}}
                    </nz-descriptions-item>
                </nz-descriptions>
            </tr>
        </ng-container>
    </tbody>

    <tbody *ngIf="type === 'clicker'">
        <tr *ngFor="let data of sortTable.data">
            <td>{{ data?.campaignName }}</td>
            <td>{{ data?.childShortUrl }}</td>
            <td>{{ data?.mobileNumber }}</td>
            <td>{{ data?.createdDate }}</td>
            <td>{{ data?.userIpAddress }}</td>
        </tr>
    </tbody>

    <tbody *ngIf="type === 'clickerreport'">
        <tr *ngFor="let data of sortTable.data">
            <td>{{ data?.campaignName }}</td>
            <td>{{ data?.childShortUrl }}</td>
            <td>{{ data?.mobileNumber }}</td>
            <td>{{ data?.createdDate }}</td>
            <td>{{ data?.userIpAddress }}</td>
        </tr>
    </tbody>

    <tbody *ngIf="type === 'short-url'">
        <tr *ngFor="let data of sortTable.data;let i = index;">
            <td>{{i+1}}</td>

            <td>{{ data?.name }}</td>
            <td>{{ data?.shortCode }}</td>
            <td>{{ data?.callbackUrl ? data.callbackUrl : '-' }}</td>
            <td>
                <nz-tag [nzColor]="'green'" *ngIf="data?.isActive === 'Y'">Yes</nz-tag>
                <nz-tag [nzColor]="'red'" *ngIf="data?.isActive === 'N'">No</nz-tag>
            </td>
            <td>
                <nz-tag [nzColor]="'green'" *ngIf="data?.isDynamic === 'Y'">Yes</nz-tag>
                <nz-tag [nzColor]="'red'" *ngIf="data?.isDynamic === 'N'">No</nz-tag>
            </td>
            <!-- <td>
                <a (click)="showDeleteConfirm(data)">Delete</a>
            </td> -->
        </tr>
    </tbody>

    <tbody *ngIf="type === 'domain'">
            <tr *ngFor="let data of sortTable.data">
            <td>{{ data?.title }}</td>
            <td>{{ data?.domainName }}</td>
            <td>{{ data?.createdDate}}</td>
            <td>
            <nz-tag [nzColor]="'green'" *ngIf="data?.isActive === 'Y'">Yes</nz-tag>
            <nz-tag [nzColor]="'red'" *ngIf="data?.isActive === 'N'">No</nz-tag>
            </td>
            <!-- <td>
                <a (click)="showDeleteConfirm(data)">Delete</a>
            </td> -->
        </tr>
    </tbody>

    <tbody *ngIf="type === 'scheduled-campaign'">
            <tr *ngFor="let data of sortTable.data; let i = index;">
                <td>{{ data?.campaignType }}</td>
                <td>{{ data?.campaignName }}</td>
                <td>{{ data?.schdeuledDate }} {{ data?.scheduledTime }}</td>
                <td>{{ data?.senderId }}</td>
                <td>{{ data?.massageText }}</td>
                <td>{{ data?.massageCount }}</td>
                <td>
                    <a nz-button nzType="link" (click)="showDeleteConfirm(data)" nzDanger>Delete</a>
                 </td>

            </tr>
    </tbody>

    <tbody *ngIf="type === 'dynamic'">
        <tr *ngFor="let data of sortTable.data">
            <td>{{ data }}</td>
            <td>{{ data?.value }}</td>
        </tr>
    </tbody>

</nz-table>